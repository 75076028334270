.aboutUs {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 20px;
  }
  .imageSection{
    width: 50%;
  }
  .imageSection img {
    width: 100%;
    height: auto;
    border-radius: 10px;
    margin-bottom: 20px;
  }
  
  .textSection {
    text-align: justify;
    padding: 10px;
    
    width: 50%;
  }
  
  @media  (max-width: 900px) {
    .aboutUs {
        flex-direction: column;
      }
      .imageSection{
        width: 100%;
      }
      .textSection {
        text-align: center;
        width: 100%;
      }
  }