@import '../variables.css';
.card {
    position: relative;
    border: 1px solid #ddd;
    border-radius: 5px;
    overflow: hidden;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--text-dark-color);
  }
  
  .card:hover {
    transform: scale(1.05);
  }
  
  .badge {
    align-self: flex-start; /* Align badge to the left */
    background-color: var(--badge-background-color);
    color: var(--badge-text-color);
    padding: 5px 10px;
    border-radius: var(--badge-border-radius);
    font-weight: var(--badge-font-weight);
    font-size: 0.8rem;
    z-index: 1;
    margin: 10px; /* Add margin to position the badge properly */
    border: 2px solid #2b3020;
  }
  
  .image-container {
    width: calc(100% - 20px); /* Full width minus padding */
    margin: 0 10px; /* Horizontal padding */
    
  }
  
  .image {
    width: 100%;
    height: 50vh;
    object-fit: cover;
  }
  @media (min-width: 480px) and (max-width: 900px) {
    .image {
        height: 30vh;
      }
  }
  
  .details {
    padding: 15px;
    text-align: center;
  }
  
  .name {
    font-size: 1.5rem;
    margin: 10px 0;
  }
  
  .price {
    font-size: 1.2rem;
    margin: 10px 0;
  }
  
  .button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 1rem;
    color: var(--button-primary-text-color);
    background-color: var(--button-primary-background-color);
    font-weight: var(--button-font-weight);
    border: none;
    border-radius: var(--button-border-radius);
    text-decoration: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border: 2px solid #2b3020;
  }
  
  .button:hover {
    background-color: var(--button-primary-hover-background-color);
    color: var(--button-primary-hover-text-color);
  }
  