@import '../variables.css';

.cookieBar {
    background-color: var(--cookies-background-color);
    padding: 15px 2%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    bottom: 20px; /* Position it slightly above the bottom */
    left: 50%; /* Center horizontally */
    transform: translateX(-50%); /* Center horizontally */
    width: 90%; /* Adjust width for a more centered look */
    max-width: 600px; /* Set a max-width for larger screens */
    z-index: 1000;
    box-sizing: border-box; /* Ensures padding is included in the total width */
    border-radius: 8px; /* Add rounded corners */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Add a slight shadow */
    border: 1px solid #90b534; /* Optional border */
}

.cookieImage {
    width: 40px; /* Adjust size as needed */
    height: auto;
    margin-right: 15px;
}

.cookieBar p {
    margin: 0;
    flex: 1;
    font-size: 14px; /* Slightly smaller font size for mobile */
    color: var(--cookies-text-color);
}

.cookieBar a {
    color: var(--cookies-link-color);
    text-decoration: none;
    font-weight: var(--cookies-link-font-weight);
}

.cookieBar a:hover {
    text-decoration: underline;
}

.confirmButton {
    background-color: var(--button-secondary-background-color);
    color: var(--button-secondary-text-color);
    font-weight: var(--button-font-weight);
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    border-radius: 5px;
    margin-left: 10px;
}

.confirmButton:hover {
    background-color: var(--button-secondary-hover-background-color);
    color: var(--button-secondary-hover-text-color);
}

/* Media Query for mobile devices */
@media (max-width: 768px) {
    .cookieBar {
        flex-direction: column; /* Stack items vertically */
        align-items: center; /* Align items to the start */
        padding: 15px 5%; /* Add more padding for mobile */
        bottom: 10px; /* Adjust positioning for mobile */
        width: 95%; /* Adjust width for mobile */
    }

    .cookieBar p {
        margin-bottom: 10px; /* Add margin below paragraph */
        width: 100%; /* Make paragraph take full width */
    }

    .cookieImage {
        margin-bottom: 10px; /* Add margin below image for spacing */
    }

    .confirmButton {
        width: 100%; /* Make button take full width */
        text-align: center; /* Center text in the button */
    }
}
