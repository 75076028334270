.hero {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 50vh;
  background-image: url('../assets/pattern.jpg');
  background-size: contain;
  background-position: center;
  background-repeat: repeat;
  color: var(--text-light-color);
  text-align: center;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.8);
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(1, 1, 1, 0.5);
  z-index: 1;
}

.content {
  position: relative;
  z-index: 2;
  max-width: 80%;
  margin: 0 auto;
}

.slogan {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.description {
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
}

.orderButton {
  display: inline-block;
  padding: 10px 20px;
  font-size: 1.2rem;
  color: var(--button-primary-text-color);
  background-color: var(--button-primary-background-color);
  font-weight: var(--button-font-weight);
  border: 2px solid #2b3020;
  border-radius: var(--button-border-radius);
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  
  text-shadow: none;
}

.orderButton:hover {
  background-color: var(--button-primary-hover-background-color);
  color: var(--button-primary-hover-text-color);
}

@media (max-width: 768px) {
  .hero {
    height: 50vh;
  }
  .slogan {
    font-size: 2rem;
  }

  .description {
    font-size: 1rem;
  }

  .orderButton {
    font-size: 1rem;
    padding: 8px 16px;
  }
}
