.carousel {
    position: relative;
    width: 100%;
    max-width: 800px;
    margin: auto;
    overflow: hidden;
  }
  
  .slide {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 87vh;
  }
  
  .slide img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  
  .prev, .next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
  }
  
  .prev {
    left: 10px;
  }
  
  .next {
    right: 10px;
  }
  
  @media (max-width: 900px) {
    .slide {
      height: 30vh;
    }
  }
  